import { graphql } from 'gatsby'
import Collection from '../containers/Collection.Author'

export default Collection

export const pageQuery = graphql`
  query allArticleByAuthorQuery(
    $skip: Int!
    $limit: Int!
    $slug: String!
    $imageQuality: Int!
  ) {
    collectionInfo: datoCmsAuthor(slug: { eq: $slug }) {
      ...ArticleAuthor
    }

    posts: allDatoCmsArticle(
      filter: {
        author: { slug: { eq: $slug } }
        website: {elemMatch: {name: {eq: "oam"}}}
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
